import React from 'react';
import { User, Setting } from '../models';

type TAppContext = {
  user?: User;
  setUser?: React.Dispatch<React.SetStateAction<User>>;
  config?: Setting;
  setConfig?: React.Dispatch<React.SetStateAction<Setting>>;
};

const AppContext = React.createContext<TAppContext>({
  user: {},
  config: {},
});

export default AppContext;
